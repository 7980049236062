<template>
    <main class="index">
            <appModal ref="child" class="policies">
                <h1 slot="heading">سياسة الأستخدام</h1>
                <main slot="content" class="modal-content">
                    <ul class="policy">
<!--                        <li v-for="(policy, index) in policies" :key="index" :data-index="index + 1">{{ policy }}</li>-->
                        <li>
                         {{policies}}
                        </li>
                    </ul>
                </main>
            </appModal>
        <smooth-scrollbar>
            <nav class="index__nav">
                <div class="container">
                    <figure class="index__nav__logo">
                        <img src="../assets/images/logos/logo.svg" alt="Shouri Logo">
                    </figure>

                    <button class="index__nav__toggler" @click="toggleMenu($event)">
                        <span></span>
                    </button>

                    <ul class="index__nav__routes" ref="routes">
                        <li>
                            <a href="#" v-scroll-to="'#top'" class="active" @click="scrollTo($event)">الرئيسية</a>
                        </li>
                        <li>
                            <a href="#" v-scroll-to="'#features'" @click="scrollTo($event)">مميزات التطبيق</a>
                        </li>
                        <li>
                            <a href="#" v-scroll-to="'#screens'" @click="scrollTo($event)">شاشات التطبيق</a>
                        </li>
                        <li>
                            <a href="#" v-scroll-to="'#faq'" @click="scrollTo($event)">أسئلة وأجوبة</a>
                        </li>
                        <li>
                            <a href="#" v-scroll-to="'#contact'" @click="scrollTo($event)">إتصل بنا</a>
                        </li>
                        <li>
                            <a href="#" v-scroll-to="'#policy'" @click="scrollTo($event)">سياسة الاستخدام</a>
                        </li>
                        <li>
                            <router-link to="login" class="register">تسجيل الدخول</router-link>
                        </li>
                    </ul>
                </div>
            </nav>

            <header class="index__header" id="top">
                <div class="container">
                    <div class="index__header__info">
                        <h1>تطبيق شوري </h1>
                        <p>تطبيق اجتماعي ٌيهدف الى مساعدة المستخدمين على الوصول الى أفضل مقدم للخدمات عن طريق الإطلاع على تقييمات واراء  مستخدمين سابقين للخدمة. كن شريكاً معنا في النجاح و أعرض جميع خدماتك بطريقه غير تقليديه و أستفد من جميع الخدمات المقدمة من شوري و التي تصبو الى تطوير و مساعدة أعمالك على التميز. </p>
                        <div class="index__header__links">
                            <a href="https://play.google.com/store/apps/details?id=com.and.amaz.shori" target="_blank">
                                <img src="../assets/images/index/icons/google-play.svg" alt="Google Play">
                                <small>إحصل علي التطبيق<b>Google Play</b></small>
                            </a>
                            <a href="https://apps.apple.com/us/app/%D8%B4%D9%88%D8%B1%D9%8A/id1489340768" target="_blank">
                                <img src="../assets/images/index/icons/app-store.svg" alt="Google Play">
                                <small>تنزيل التطبيق من<b>App Store</b></small>
                            </a>
                        </div>
                    </div>

                    <div class="index__header__screens">
                        <figure class="index__header__screens__main">
                            <img src="../assets/images/index/iphone-moc.png" alt="Iphone Moc">
                        </figure>
                    </div>
                </div>
            </header>

            <section class="index__features" id="features">
                <div class="container">
                    <h5>نساعدك علي تقديم خدمتك بصوره محترفه ومتكاملة</h5>
                    <h1>مميزات شوري</h1>
                    <div class="index__features__container">
                        <div class="index__features__feature">
                            <figure>
                                <img src="../assets/images/index/icons/combined-shape.svg" alt="Icon">
                            </figure>
                            <h3>تميز بخدماتك المقدمة</h3>
                            <p>امنح المستخدمين المحتملين الفرصه للإطلاع على جودة الخدمات المقدمة عن طريق استعراض تجارب و اراء مستخدمين سابقين بكل شفافيه.</p>
                        </div>
                        <div class="index__features__feature">
                            <figure>
                                <img src="../assets/images/index/icons/combined-shape (1).svg" alt="Icon">
                            </figure>
                            <h3>أكسب المزيد من العملاء</h3>
                            <p>نتيح لك فرصه استقبال طلبات الإتصال مباشرةً من العملاء المحتملين</p>
                        </div>
                        <div class="index__features__feature">
                            <figure>
                                <img src="../assets/images/index/icons/combined-shape (2).svg" alt="Icon">
                            </figure>
                            <h3>العروض و الخصومات </h3>
                            <p>نتيح لك فرصه تقديم العروض و الخصومات بشكل مباشر للمستخدمين مع خدمات شوري عروض .</p>
                        </div>
                        <div class="index__features__feature">
                            <figure>
                                <img src="../assets/images/index/icons/combined-shape (3).svg" alt="Icon">
                            </figure>
                            <h3>تحليل و قياس الأداء </h3>
                            <p>نوفر لك تحليلاً لقياس جودة الأداء و قياسه بالمقارنة مع قطاع أعمالك.</p>
                        </div>
                        <div class="index__features__feature">
                            <figure>
                                <img src="../assets/images/index/icons/combined-shape (4).svg" alt="Icon">
                            </figure>
                            <h3>التطوير </h3>
                            <p>يقدم لك المتسوق الخفي فرصه لتقييم خدماتك و الإطلاع على جودته و نمنحك فرصه عرض و تثبيت التقييم في واجهه المركز. </p>
                        </div>
                    </div>
                </div>
            </section>

            <section class="index__screens" id="screens">
                <div class="container">
                    <h5>تعرف علي خدماتنا بشكل اقرب</h5>
                    <h1>شاشات التطبيق</h1>
                    <Carousel 
                    class="index__carousel"
                    :autoplay="true"
                    :nav="false"
                    :items="3"
                    :dots="false"
                    :responsive="{
                        0: {
                            items: 1,
                            nav: false
                        },
                        652: {
                            items: 2,
                            nav: false
                        },
                        768: {
                            items: 3,
                            nav: false
                        },
                        992: {
                            items: 4,
                            nav: false
                        }}"
                    >

                    <div class="index__carousel__slide">
                        <img src="../assets/images/index/screens/welcome.png" alt="Screen">
                    </div>
                    <div class="index__carousel__slide">
                        <img src="../assets/images/index/screens/login.png" alt="Screen">
                    </div>
                    <div class="index__carousel__slide">
                        <img src="../assets/images/index/screens/map.png" alt="Screen">
                    </div>
                    <div class="index__carousel__slide">
                        <img src="../assets/images/index/screens/screen1.png" alt="Screen">
                    </div>
                    <div class="index__carousel__slide">
                        <img src="../assets/images/index/screens/carousel-1.png" alt="Screen">
                    </div>
                    <div class="index__carousel__slide">
                        <img src="../assets/images/index/screens/comments.png" alt="Screen">
                    </div>
                    <div class="index__carousel__slide">
                        <img src="../assets/images/index/screens/offers.png" alt="Screen">
                    </div>
                </Carousel>
                </div>
            </section>

            <section class="index__faq" id="faq">
                <div class="container">
                    <h5>استفساراتكم محل اهتمامنا و نسعد دائما بالتواصل معكم</h5>
                    <h1>أسئله و أجوبه</h1>
                    <div 
                        :class="{'index__faq__question': true, 'opened': index == 0}"
                        v-for="(question, index) in faq"
                        :key="question.id"
                        @click="toggleOpen($event)">
                        <button class="shrink">-</button>
                        <button class="open">+</button>
                        <h2>{{ question.question }}</h2>
                        <p>{{ question.answer }}</p> 
                    </div>
                </div>
            </section>

            <section class="index__contact" id="contact">
                <div class="container">
                    <div class="index__contact__headers">
                        <h5>نهتم باستقبال اقتراحاتكم و استفساراتكم</h5>
                        <h1>تواصل معنا</h1>
                    </div>
                    <form @submit.prevent="contactUs()">
                        <div class="input-container half">
                            <input type="text" placeholder="الاسم" ref="name">
                            <figure></figure>
                        </div>
                        <div class="input-container half">
                            <input type="email" placeholder="البريد الالكتروني" ref="email">
                            <figure></figure>
                        </div>
                        <div class="input-container half">
                            <input type="tel" placeholder="رقم الجوال" ref="phone">
                            <figure></figure>
                        </div>
                        <!-- <div class="input-container half select">
                            <select v-model="inqury">
                                <option value="" disabled selected="selected">استفسار</option>
                                <option value="3">اختيار 1</option>
                                <option value="1">اختيار 2</option>
                                <option value="2">اختيار 3</option>
                                <option value="3">اختيار 4</option>
                            </select>
                        </div> -->
                        <div class="input-container">
                            <textarea placeholder="نص الرسالة" ref="details"></textarea>
                        </div>
                        <button type="submit" @click="contactShory($event)">ارسال</button>
                    </form>
                </div>
            </section>

            <section class="index__policy" id="policy">
                <div class="container">
                    <h5>شروط استخدام منصة شوري</h5>
                    <h1>سياسة الأستخدام </h1>
                    <p>ان المنصة و التطبيق الالكتروني شوري والمملوك لمؤسسة شوري لخدمات الاعمال، سجل تجاري رقم 1010599017 والمشار إليه في هذه الاتفاقية بـ "المنصة او التطبيق" هو تطبيق عام متاح للاستخدام من قبل الجميع. ويخضع دخول المستخدم واستخدامه لهذا المنصة او التطبيق للشروط والأحكام المذكورة في هذه الوثيقة. يهدف المنصة او التطبيق إلى مساعدة أفراد المجتمع في البحث عن مقدمين الخدمات المختلفة بحسب آراء وتجارب أفراد آخرين وإيصال المعلومة إلى من يحتاجها بدون غرض التشهير أو الإخلال بأي من القوانين المعمول بها والسارية في المملكة العربية السعودية. وتقوم فكرة المنصة او التطبيق على انشاء ملفات مستقلة لمقدمي الخدمات في المملكة للمساعدة على إيجاد مقدم الخدمة المناسب بغرض تقييم مستوى الخدمة المقدمة وذلك بحسب أراء وتقييم زوار ومستخدمي المنصة او التطبيق ورأي الغير وبهدف انشاء مصدر موثوق به لجميع الباحثين عن المعلومات . </p>
                    <button @click="openModal()">شروط استخدام التطبيق</button>
                </div>
            </section>

            <footer class="index__footer">
                <div class="container">
                    <div class="index__footer__links">
                        <a href="#">
                            <img src="../assets/images/index/icons/google-play.svg" alt="Google Play">
                            <small>إحصل علي التطبيق<b>Google Play</b></small>
                        </a>
                        <a href="#">
                            <img src="../assets/images/index/icons/app-store.svg" alt="Google Play">
                            <small>تنزيل التطبيق من<b>App Store</b></small>
                        </a>
                    </div>

                    <p>جميع الحقوق محفوظة - ٢٠٢٠ - تطبيق شوري</p>
                </div>
            </footer>
        </smooth-scrollbar>
    </main>
</template>

<script>
import Carousel from 'vue-owl-carousel'
import appModal from '../components/appModal'

export default {
    data() {
        return {
            name: null,
            email: null,
            phone: null,
            inqury: null,
            details: null,
            policies: [],
            faq: []
        }
    },
    components: {
        Carousel,
        appModal
    },
    methods: {
        scrollTo(e) {
            const anchor = e.target,
                anchors = this.$el.querySelectorAll('nav li a'),
                menuButton = e.target.closest('.index__nav__routes').parentNode.querySelector('.index__nav__toggler');

            anchors.forEach( element => {
                element.classList.remove('active')
            })

            anchor.classList.add('active')

            menuButton.classList.remove('as-close')

            this.$refs.routes.classList.remove('active')
        },
        toggleMenu: function(e) {
            const btn = e.target.tagName == 'BUTTON' ? e.target : e.target.closest('button')

            btn.classList.toggle('as-close')
            this.$refs.routes.classList.toggle('active')
        },
        toggleOpen: function(e) {
            const questions = this.$el.querySelectorAll('.index__faq__question'),
                target = e.target.tagName == "DIV" ? e.target : e.target.parentNode

            questions.forEach(question => {
                question.classList.remove('opened')
            })

            target.classList.add('opened')
        },
        contactUs: function() {

        },
        openModal: function() {

            // eslint-disable-next-line
            EventBus.$emit('openModal')

            const mainElement = this.$el

            mainElement.classList.add('fixed')


        },
        formatDataAsJSON: function() {
            let data = {}

            data.name = this.$refs.name.value
            data.email = this.$refs.email.value
            data.phone = this.$refs.phone.value
            data.details = this.$refs.details.value

            return data
        },
        validateEmail: function(email) {

            const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
            return regex.test(String(email).toLowerCase());

        },
        validateInputsNotEmpty: function(){
            let result = {
                status: true,
                elements: []
            },
            inputs = []

            inputs.push(this.$refs.name)
            inputs.push(this.$refs.email)
            inputs.push(this.$refs.phone)
            inputs.push(this.$refs.details)

            inputs.forEach(input => {
                // eslint-disable-next-line
                if(!!input.value) {

                    result.staus ? result.staus = true : '';

                } else {

                    result.status = false

                    result.elements.push(input)

                }
            })

            return result

        },
        contactShory() {
            let everyThingIsGoingWell = false;

            if (this.validateInputsNotEmpty().status) {

                everyThingIsGoingWell = true;

            } else {

                everyThingIsGoingWell = false;

                this.validateInputsNotEmpty().elements.forEach(element => {
                    element.classList.add('error');
                })
            }

            if(this.validateEmail(this.$refs.email.value)) {

                everyThingIsGoingWell = everyThingIsGoingWell ? true : false;

            } else {

                everyThingIsGoingWell = false;

                this.$refs.email.classList.add('error');

                // eslint-disable-next-line
                EventBus.$emit('searchError', 'الرجاء كتابة بريد اليكتروني صحيح');

            }

            if (everyThingIsGoingWell) {

                let url = `${this.$store.state.mainURL}/api/v1/contact-us`,
                    mode = 'cors',
                    headers = new Headers(),
                    method = 'POST',
                    data = {
                        name: this.$refs.name.value,
                        email: this.$refs.email.value,
                        phone: this.$refs.phone.value,
                        body: this.$refs.details.value
                    },
                    inputs = [];

                inputs.push(this.$refs.name)
                inputs.push(this.$refs.email)
                inputs.push(this.$refs.phone)
                inputs.push(this.$refs.details)

                inputs.forEach(input => {
                    input.classList.remove('error');
                });

                headers.append('Accept', 'application/json');

                fetch(url, {
                    mode,
                    method,
                    headers: {
                        'Accept': 'application/json',
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify(data)
                })
                .then(response => response.json())
                .then( () => {

                    inputs.forEach(input => {
                        input.value = '';
                    })

                    // eslint-disable-next-line
                    EventBus.$emit('searchError', 'تم إرسال الطلب بنجاح');

                })
            }
        }
    },
    mounted() {
        const nav = this.$el.querySelector('nav'),
            mainElement = this.$el
        this.$el.addEventListener('scroll', (e) => {
            e.target.scrollTop >= 5 ? nav.classList.add('sticky') : nav.classList.remove('sticky')
        })

        // eslint-disable-next-line
        EventBus.$on('closeModal', () => {
            mainElement.classList.remove('fixed')
        })

        const PlociesUrl = `${this.$store.state.mainURL}/api/v1/terms`,
            FAQUrl = `${this.$store.state.mainURL}/api/v1/FaQ`,
            headers = new Headers(),
            mode = 'cors',
            method = 'GET'

            headers.append('Accept', 'application/json')

        fetch(PlociesUrl, {
            mode, 
            method,
            headers,
        })
        .then(response => response.json())
        .then(data => {
            this.policies = data.data
            // console.log(this.policies)
        })

        fetch(FAQUrl, {
            mode, 
            method,
            headers,
        })
        .then(response => response.json())
        .then(data => {
            this.faq = data.data
        })
    }
}
</script>

<style lang="sass">
.index
    position: absolute
    top: 0
    left: 0
    max-width: 100vw
    width: 100vw
    height: 100vh
    background-color: #fff
    overflow-x: hidden
    overflow-y: scroll
    scroll-behavior: smooth

    &.fixed
        overflow: hidden

    &::-webkit-scrollbar
        width: 3px

    /* Track */
    &::-webkit-scrollbar-track
        background: #f1f1f1
        border-radius: 5px

    /* Handle */
    &::-webkit-scrollbar-thumb
        background: #E7B500
        border-radius: 5px

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover
        background: #DBAB00

    &__nav
        position: fixed
        top: 0
        left: 0
        width: 100vw
        background-color: transparent
        padding: 10px 0
        z-index: 10
        box-shadow: 0px 0px 0px #eee
        transition: background-color .3s ease, box-shadow .2s ease
        will-change: background-color, box-shadow

        &.sticky
            background-color: #fff
            box-shadow: 1px 5px 15px #eee

        .container
            display: flex
            justify-content: space-between
            align-items: center

        &__logo
            width: 60px
            height: 60%

            img
                max-width: 100%
                max-height: 100%
                object-fit: contain

        &__toggler
            width: 50px
            height: 50px
            background-color: transparent
            border: 0
            position: relative
            z-index: 99999
            cursor: pointer

            @media screen and (min-width: 810px)
                display: none

            span
                position: absolute
                top: 50%
                left: 50%
                transform: translate(-50%, -50%)
                width: calc(100% - 10px)
                height: 3px
                background-color: #000
                border-radius: 30px
                transition: width .3s ease, height .2s ease
                will-change: width, height

                &::after
                    content: ''
                    position: absolute
                    top: -10px
                    left: 0
                    width: 90%
                    height: 3px
                    background-color: #000
                    border-radius: 30px
                    transition: all .3s ease
                    will-change: width, transform, left

                &::before
                    content: ''
                    position: absolute
                    top: 10px
                    left: 0
                    width: 70%
                    height: 3px
                    background-color: #000
                    border-radius: 30px
                    transform-origin: center
                    transition: all .3s ease
                    will-change: width, transform, left

            &:hover
                span
                    &::after, &::before
                        width: 100%

            &.as-close
                span
                    height: 0

                    &::after
                        left: 9px
                        width: 100%
                        transform: rotate(45deg) translateY(13px)
                    &::before
                        left: 9px
                        width: 100%
                        transform: rotate(-45deg) translateY(-13px)

        &__routes
            list-style: none

            li
                display: inline-block

            a
                color: #323232
                padding: 0 5px 5px
                text-decoration: none
                position: relative

                &::after
                    content: ''
                    position: absolute
                    bottom: 0
                    left: 50%
                    transform: translateX(-50%)
                    width: 0
                    height: 3px
                    border-radius: 10px
                    background-color: #080600
                    transition: width .3s ease
                    will-change: width

                &.active
                    color: #080600
                    font-weight: bold

                    &::after
                        width: 50%
                         
                &:hover::after
                    width: 50%

                &.register
                    background-color: #080600
                    color: #fff
                    padding: 5px 10px
                    border-radius: 30px
                    font-size: .75em


                @media screen and (max-width: 861px)
                    padding: 0 5px 5px

                @media screen and (min-width: 862px)
                    padding: 0 10px 5px

                @media screen and (max-width: 935px)
                    font-size: .9em

                @media screen and (min-width: 1015px)
                    padding: 0 15px 5px

            @media screen and (max-width: 810px)
                position: absolute
                top: -100vh
                left: 0
                width: 100vw
                display: flex
                flex-direction: column
                align-items: center
                justify-content: center
                transition: top .3s ease
                z-index: 999

                li
                    height: calc(100vh / 7)
                    width: 100vw
                    background-color: #fff
                    display: flex
                    align-items: center
                    justify-content: center
                    border-bottom: 1px solid #eee
                a
                    color: #E7B500

                &.active
                    top: 0
                
    &__header
        width: 100vw
        height: 100vh
        min-height: 670px
        position: relative
        
        &::before
            content: ''
            position: absolute
            top: 0
            left: 0
            width: 100%
            height: calc(100% - 15px)
            background-image: url('../assets/images/index/header-bg.png')
            background-size: contain
            background-repeat: no-repeat
            filter: drop-shadow( 5px 15px 0px #f3f3f3 )
            z-index: 1

        .container
            height: 100%
            display: flex
            align-items: center

            @media screen and(max-width: 768px)
                display: block
                position: relative

        &__info
            width: 50%
            position: relative
            z-index: 3
            will-change: position, width

            @media screen and(max-width: 768px)
                width: 100%
                max-width: 550px
                position: absolute
                top: 50%
                left: 50%
                transform: translate(-50%, -50%)

            h1
                margin-bottom: 15px
            
            p
                font-size: .9em
                padding: 0  0 0 20px
                font-size: 16px
                font-stretch: norma
                lline-height: 1.81
                letter-spacing: 0.15px
                color: #050400
                max-width: 548px

        &__links
            display: flex
            align-items: center
            justify-content: flex-start
            margin: 60px 0 20px
            a
                text-decoration: none
                color: #080600
                display: flex
                width: 170px
                min-width: 150px
                max-height: 50px
                padding: 7px 7px 10px
                margin-left: 10px
                border: 1px solid #323232
                border-radius: 6px

            small
                padding-right: 10px
                font-size: .7em
                color: #323232

            b
                display: block
                line-height: 12px
                font-size: 1.1rem

            img
                width: 30px

        &__screens
            width: 50%
            min-width: 330px

            &__main
                width: 55%
                max-width: 260px
                min-width: 200px
                margin: 0 20px
                height: 60vh
                min-height: 420px
                max-height: 435px
                position: relative

                @media screen and(max-width: 931px)
                    margin: 0 auto

                @media screen and(max-width: 768px)
                    display: none

                &::before
                    content: ''
                    position: absolute
                    top: 4px
                    left: 10px
                    width: calc(100% - 20px)
                    height: calc(100% - 9px)
                    background-image: url('../assets/images/index/screens/header-1.png')
                    background-size: cover
                    background-repeat: no-repeat
                    border-radius: 30px
                    z-index: 2
                    box-shadow: 1px 10px 25px #ccc

                &::after
                    content: ''
                    position: absolute
                    top: 10%
                    left: -70%
                    width: 80%
                    height: 80%
                    background-image: url('../assets/images/index/screens/header-2.png')
                    background-size: cover
                    z-index: 1

                    @media screen and(max-width: 931px)
                        display: none

                img
                    width: 100%
                    height: 100%
                    position: relative
                    z-index: 3


    &__features
        position: relative
        width: 100%
        max-width: 100vw
        min-height: 100vh

        &::after
            content: ''
            position: absolute
            top: 0
            left: 0
            width: 160%
            height: 100%
            transform: skew(0deg, -10deg)
            z-index: 2
            background-color: #f8f8f8

        &::before
            content: ''
            position: absolute
            top: 0
            left: -10%
            width: 160%
            height: 100%
            transform: skew(0deg, -15deg)
            z-index: 1
            background-color: #fcfcfc

        .container
            position: relative
            z-index: 3
            text-align: center
            padding: 20vh 0

        h5
            line-height: 10px
            color: #ccc
                
        h1
            font-size: calc(6vmin)

        &__container
            height: 100%
            margin-top: 50px
            display: flex
            flex-wrap: wrap
            align-items: flex-start
            justify-content: center
            
        &__feature
            width: calc(33.333% - 20px)
            min-width: 300px
            padding: 30px 20px
            margin: 10px
            border-radius: 15px
            box-shadow: 0px 0px 0px #ccc
            background-color: #f8f8f8
            transition: box-shadow .3s ease, background-color .3s ease

            // &:hover
            //     box-shadow: 4px 4px 15px #ddd
            //     background-color: #fff

            
            figure
                width: 90px
                height: 90px
                margin: 0 auto 10px
                background-color: #e7b400
                padding: 10px
                display: flex
                align-items: center
                justify-content: center
                border-radius: 50%

                img
                    max-width: 90%
                    max-height: 90%

            h3
                margin-bottom: 20px

    &__screens
        position: relative
        margin: 120px 0 40px
        z-index: 4

        .container
            position: relative
            text-align: center

        h5
            line-height: 10px
            color: #ccc

        h1
            font-size: calc(6vmin)

    &__carousel
        position: relative
        direction: ltr
        width: 100%
        height: 380px
        margin: 40px 0
        position: relative

        &__slide
            max-height: 500px
            max-width: 300px
            padding: 40px
            margin: 0 auto

            img
                width: 100%
                object-fit: contain
                border-radius: 10px
                box-shadow: 4px 4px 15px #ddd

    &__faq
        position: relative
        padding: 120px 0

        &::before
            content: ''
            position: absolute
            top: 0
            left: 0
            width: 100%
            height: 100%
            background-color: #3b3b3b
            clip-path: polygon(0 13%, 100% 0, 100% 100%, 0 95%)
            z-index: 1

        .container
            position: relative
            z-index: 2
            text-align: center
            color: #fff
            
            h5
                line-height: 10px
                color: #ccc

            h1
                font-size: calc(6vmin)

        &__question
            margin: 10px
            padding: 20px
            display: flex
            flex-direction: column
            align-items: flex-start
            justify-content: center
            position: relative
            border: 1px solid #fff
            border-radius: 5px
            cursor: pointer
            user-select: none

            button
                padding: 5px
                color: #fff
                background-color: transparent
                border: 0
                cursor: pointer
                font-size: 2em
                outline: 0
                position: absolute
                top: 10px
                left: 10px

                &.shrink
                    opacity: 0

            h2
                margin-bottom: 15px
                padding-left: 10px

            p
                width: 100%
                height: 0
                text-align: right
                overflow-y: scroll
                transition: height .3s ease
                will-change: height

                &::-webkit-scrollbar
                    width: 1px

                /* Track */
                &::-webkit-scrollbar-track
                    background: #3b3b3b
                    border-radius: 5px

                /* Handle */
                &::-webkit-scrollbar-thumb
                    background: #E7B500
                    border-radius: 5px

                /* Handle on hover */
                &::-webkit-scrollbar-thumb:hover
                    background: #DBAB00

            &.opened
                position: relative
                
                .shrink
                    opacity: 1

                .open
                    opacity: 0

                h2
                    color: #e7b400

                p
                    height: 70px

    &__contact
        margin: 50px 0
        position: relative
        width: 100vw
        min-height: 500px

        @media screen and (max-width: 768px)
            padding: 40px 0

        &::before
            content: ''
            position: absolute
            top: 0
            right: 0
            height: 100%
            background-image: linear-gradient(114deg, #ffd529 10%, #fd8440 93%)
            z-index: 1
            width: 60%
            border-radius: 60px 0 0 60px

            @media screen and (max-width: 768px)
                width: 100%
                border-radius: 0

        .container
            position: relative
            z-index: 2
            display: flex
            flex-wrap: wrap
            align-items: center
            justify-content: space-between

        &__headers
            text-align: center
            width: calc(40% - 20px)
            min-width: 300px

            @media screen and (max-width: 768px)
                width: 100%

            h5
                line-height: 10px
                color: #333

            h1
                font-size: calc(6vmin)


        form
            width: calc(60% - 20px)
            min-width: 300px
            min-height: 380px
            padding: 20px
            background-color: #fff
            box-shadow: 0 2px 55px 0 rgba(0, 0, 0, 0.08)
            display: flex
            flex-wrap: wrap
            align-items: center
            justify-content: flex-start
            border-radius: 30px

            @media screen and (max-width: 768px)
                width: 100%
            
            .input-container
                width: 100%
                min-height: 57px
                position: relative
                min-width: 120px
                border: 1px solid #e7e7e7
                margin: 5px
                border-radius: 7px
                background-color: #f5f5f5

                &.half
                    width: calc(50% - 10px)

            input, select, textarea
                display: block
                width: 100%
                height: 100%
                border: 1.5px solid transparent
                font-family: inherit
                padding: 7px
                resize: none
                border-radius: 7px
                outline: 0
                font-size: 1.2em
                background-color: transparent
                position: relative
                z-index: 3

                &.error
                    border: 1.5px solid #c0392b

            .input-container.select::after
                content: ''
                position: absolute
                top: 50%
                left: 5px
                transform: translateY(-50%) rotate(-45deg)
                width: 30px
                height: 30px
                background: url('../assets/images/index/icons/select.svg')
                background-repeat: no-repeat
                background-position: center center
                background-size: contain
                z-index: 2

            select
                -webkit-appearance: none
                -moz-appearance: none

                &::-ms-expand
                    display: none

            button
                width: 150px
                height: 61px
                background-color: #000000
                color: #e7e7e7
                border: 0
                font-family: inherit
                font-size: 1.1em
                border-radius: 6px
                outline: 0
                cursor: pointer
                margin: 5px

    &__policy
        position: relative
        text-align: center
        margin-top: 100px
        padding: 30px 0 120px
        background-color: #000

        @media screen and (max-width: 768px)
            margin-top: 130px

        @media screen and (max-width: 531px)
            padding: 30px 0 150px

        @media screen and (max-width: 481px)
            margin-top: 160px

        &::before
            content: ''
            position: absolute
            top: -20%
            left: 50%
            transform: translateX(-50%)
            width: 120%
            height: 100%
            padding: 20px
            background-color: #eee
            font-weight: bold
            font-size: 1.1em
            z-index: 1
            clip-path: polygon(0 10%, 100% 0, 100% 100%, 0% 100%)

        &::after
            content: 'استخدام هذا المنصة او التطبيق يُشكل قبولاً تاماً لهذه الاتفاقية. إذا كنت لا توافق على هذه الشروط الرجاء عدم استخدام المنصة او التطبيق'
            position: absolute
            bottom: 0
            left: 50%
            transform: translateX(-50%)
            width: 90vw
            padding: 20px
            background-color: #e7b400
            font-weight: bold
            font-size: 1.1em
            border-radius: 10px
            max-width: 700px
            z-index: 3

            @media screen and (max-width: 768px)
                font-size: 1em
                padding: 10px
                bottom: 20px

        .container
            position: relative
            z-index: 2

            h5
                line-height: 10px
                color: #c3c3c3

            h1
                font-size: calc(6vmin)

            p
                line-height: 30px
                text-align: right
                margin-bottom: 30px

            button
                width: 250px
                padding: 10px
                border-radius: 6px
                color: #e7e7e7
                background-color: #000
                font-family: inherit
                border: 0
                outline: 0
                cursor: pointer
                box-shadow: 0px 4px 15px #ccc

    &__footer
        position: relative
        background-color: #000
        text-align: center

        &__links
            display: flex
            justify-content: center
            align-items: center
            padding: 40px 0 20px
        
        a
            text-decoration: none
            color: #080600
            display: flex
            width: 170px
            min-width: 150px
            max-height: 50px
            padding: 7px 7px 10px
            margin-left: 10px
            border: 1px solid #323232
            border-radius: 6px
            background-color: #ffffff

        small
            padding-right: 10px
            font-size: .7em
            color: #323232

        b
            display: block
            line-height: 12px
            font-size: 1.1rem

        img
            width: 30px

        p
            color: #eee

</style>
