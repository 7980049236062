<template>
    <section class="login">
        <notification ref="notification">
            <p v-for="(message, index) in errorMessages" :key="index">{{ message }}</p>
        </notification>

        <div class="container">
            <aside>
                <img src="../../assets/images/logos/logo.png" alt="Shory Logo">
            </aside>

            <form>
                <small style="color:darkred">لقد تم تحديث آلية تسجيل الدخول الي اسم السمتخدم بدلا من كلمة المرور</small>
                <br/><br/>
                <h1 slot="heading"> تغير الية تسجيل الدخول</h1>
                <input type="text" ref="username" name="username" placeholder="اسم المستخدم" required autocomplete="off">
                <button @click="updateAuth($event)"> التالي</button>



<!--                <div class="outer-link">-->
<!--                    <span>عميل جديد؟ <router-link to="./register">تسجيل</router-link></span>-->
<!--                </div>-->
            </form>
        </div>
    </section>
</template>

<script>

import notification from '../../components/appNotification'

export default {
    name: 'login',
    components: {
        notification
    },
    data() { 
        return {
            password: '',
            errorMessages: []
        }
    },
    methods: {

        updateAuth: function(e) {

            e.preventDefault();
            let content = e.target.textContent,
                loginData = {},
                url = `${this.$store.state.mainURL}/center/v1/updateAuth`,
                headers = new Headers(),
                method = 'POST',
                data = {},
                next = 'login',
                UT = 'CB<&]nn)-h%bZCK*'

            e.target.textContent = ''
            e.target.classList.add('loading')

            headers.append('Accept', 'application/json')
            headers.append('content-type', 'application/json')

            data.username = this.$refs.username.value
            data.currentAuth = localStorage.getItem('currentAuth');

                loginData.url = url
                loginData.next = next
                loginData.UT = UT
                loginData.request = {
                    mode: 'cors',
                    method,
                    headers,
                    body: JSON.stringify(data)
                }

                this.$store.dispatch('doUpdateAuth', loginData)

                // eslint-disable-next-line
                EventBus.$on('success', () => {
                    e.target.classList.add('success')
                    e.target.classList.remove('loading')

                    setTimeout(() => {
                        e.target.classList.remove('success')
                        e.target.textContent = content
                    }, 1000)
                })

                // eslint-disable-next-line
                EventBus.$on('failed', (response) => {
                    this.errorMessages = []
                    !this.errorMessages.includes(response) ? this.errorMessages.push(response) : ''
                    // eslint-disable-next-line
                    EventBus.$emit('showErrorNotification')

                    e.target.classList.add('failed')
                    e.target.classList.remove('loading')

                    setTimeout(() => {
                        e.target.classList.remove('failed')
                        e.target.textContent = content
                    }, 1000)
                })
        }
    },
    mounted() {
        const inputs = this.$el.querySelectorAll('input')
        
        inputs.forEach(input => {
            input.addEventListener('keyup', () => {
                input.style.borderRightColor = '#eee'
            })
        })

        
        // eslint-disable-next-line
        EventBus.$on('emailSent', () => {

            !this.errorMessages.includes('تم إرسال رابط تعديل الباسورد الخاص بكم على بريدكم الإليكتروني') ? this.errorMessages.push('تم إرسال رابط تعديل الباسورد الخاص بكم على بريدكم الإليكتروني') : ''

            // eslint-disable-next-line
            EventBus.$emit('showErrorNotification')
        })
    }
}
</script>

<style lang="sass" scoped>

.login
    width: 100%
    height: 100%
    position: relative
    
    .container
        width: 95%
        height: 100%
        max-width: 745px
        max-height: 545px
        border-radius: 20px
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
        background-color: #fff
        box-shadow: 15px 15px 15px #ddd
        display: flex
        align-items: center
        overflow: hidden

        @media screen and (max-width: 768px)
            max-width: 600px

        @media screen and (max-width: 621px)
            max-height: auto
            flex-wrap: wrap

        aside
            width: 30%
            min-width: 230px
            height: 100%
            background-image: url('../../assets/images/stars.svg')
            background-size: cover
            display: flex
            align-items: center
            justify-content: center
            pointer-sevents: none

            @media screen and (max-width: 621px)
                width: 100%
                height: 170px

            img
                max-width: 90%

        form
            width: 100%
            height: 100%
            display: flex
            flex-direction: column
            align-items: center
            justify-content: center
            
            @media screen and (max-width: 621px)
                width: 100%
                height: calc(100% - 170px)

            h1
                margin-bottom: 15px

            input:not([type="submit"])
                border: 1px solid #eee
                border-right-width: 3px
                box-shadow: 2px 2px 5px #eee
                font-family: inherit
                outline: 0
                border-radius: 25px
                padding: 4px 13px
                font-size: 1.1em
                font-weight: 100
                margin: 5px 0
                width: 240px
                transition: border-color .3s ease

                &::placeholder
                    color: #ccc
                    font-size: 14px
            
            button
                background-color: #000
                color: #fff
                border-radius: 25px
                border: 0
                font-family: inherit
                cursor: pointer
                font-size: 1.1em
                padding: 4px 13px
                margin-top: 15px
                width: 240px
                height: 40px
                outline: none
                transition: all .3s ease
                position: relative

                &::before
                    content: ''
                    position: absolute
                    top: 50% 
                    left: 50%
                    transform: translate(-50%, -50%)
                    width: 40px
                    height: 40px
                    background-color: #999
                    border-radius: 50%
                    transition: all .3s ease
                    z-index: -1
                    visibility: hidden

                &::after
                    content: url('../../assets/images/thumb.svg')
                    position: absolute
                    top: 50% 
                    left: 50%
                    transform: translate(-50%, -50%) scale(.5)
                    padding: 10px 7px 10px 10px
                    width: 40px
                    height: 40px
                    border-radius: 50%
                    background-color: #000
                    transition: back .3s ease
                    visibility: hidden

                &.loading
                    width: 40px
                    height: 40px
                    border-radius: 50%
                    pointer-events: none

                    &::before
                        visibility: visible
                        animation: scale-up 1s linear infinite

                        @keyframes scale-up
                            0%
                                transform: translate(-50%, -50%) scale(1)
                                opacity: 1
                            100%
                                transform: translate(-50%, -50%) scale(2)
                                opacity: 0

                &.success
                    width: 40px
                    height: 40px
                    border-radius: 50%
                    padding: 20px
                    background-color: #2ecc71
                    pointer-events: none

                    &::after
                        visibility: visible
                        background-color: #2ecc71
                        transform: translate(-50%, -50%) scale(1)

                &.failed
                    width: 40px
                    height: 40px
                    border-radius: 50%
                    background-color: #e74c3c
                    pointer-events: none

                    &::after
                        visibility: visible
                        background-color: #e74c3c
                        transform: translate(-50%, -50%) scale(1) rotate(-180deg)

            .outer-link
                margin-top: 5px
                font-size: .9em

                a
                    text-decoration: none
                    color: #000
                    font-weight: bold
                
            hr.horizental
                display: block
                width: 240px
                height: 1px
                margin-top: 50px
                border: 0
                background-color: #E7B500

                @media screen and (max-height: 480px)
                    margin-top: 10px
                @media screen and (max-height: 570px)
                    margin-top: 20px

</style>

